import { shippingCostFix } from '../custom';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const checkoutLoadedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CHECKOUT_LOADED,
  handler: ({ detail }) => {
    logger('Checkout Loaded');
    console.log(detail);

    shippingCostFix();
  },
};
