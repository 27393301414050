import { shippingCostFix } from '../custom';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const useNewShippingAddressSelectedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.USE_NEW_SHIPPING_ADDRESS_SELECTED,
  handler: (event) => {
    logger('Use New Shipping Address Selected');
    console.log(event.detail);

    shippingCostFix();
  },
};
