import { shippingCostFix } from '../custom';
import type { EbizioOrderEventConfig } from '../types';
import { EBIZIO_ORDER_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const orderConfirmationLoadedConfig: EbizioOrderEventConfig = {
  event: EBIZIO_ORDER_EVENT_TYPE.ORDER_CONFIRMATION_LOADED,
  handler: ({ detail }) => {
    logger('Order Confirmation Loaded');
    console.log(detail);

    shippingCostFix();

    setInterval(() => {
      shippingCostFix();
    }, 500);
  },
};
