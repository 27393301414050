import { Cart } from '@bigcommerce/checkout-sdk';
import { logger } from '../../utils';

export const shippingCostFix = () => {
  logger('Updating shipping cost (DEV)');

  // remove any existing replacement nodes
  document.querySelectorAll('.its-replacement-value').forEach(node => node.remove());

  // make new ones
  const shippingPriceNodes: NodeListOf<HTMLSpanElement> = document.querySelectorAll("[data-test='cart-shipping'] [data-test='cart-price-value']")
   
  shippingPriceNodes.forEach(shippingPriceNode => {
    if (shippingPriceNode.textContent === 'Free' || shippingPriceNode.textContent === '--') {
      const newNode = document.createElement('span');
      newNode.setAttribute('data-test', 'cart-price-value');
      newNode.classList.add('its-replacement-value');
      newNode.textContent = 'TBD';

      shippingPriceNode.parentElement!.appendChild(newNode);
      shippingPriceNode.style.display = 'none';
    }
  });
};
