import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const customerSignedOutConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CUSTOMER_SIGNED_OUT,
  handler: ({ detail }) => {
    logger('Customer Signed Out');
    console.log(detail);
  },
};
