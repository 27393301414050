import { shippingCostFix } from '../custom';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const shippingMethodSelectedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SHIPPING_METHOD_SELECTED,
  handler: ({ detail }) => {
    logger('Shipping Method Selected');
    
    shippingCostFix();
  },
};
