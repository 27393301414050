import { shippingCostFix } from '../custom';
import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const cartSummaryDesktopMountedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_DESKTOP_MOUNTED,
  handler: ({ detail }) => {
    logger('Cart Summary Desktop Mounted');
    console.log(detail);

    shippingCostFix();
  },
};
