import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger, setActiveStepOnBody } from '../utils';

export const paymentStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_STEP_LOADING,
  handler: ({ detail }) => {
    logger('Payment Step Loading');
    setActiveStepOnBody(detail.activeStepType);
    console.log(detail);
  },
};
