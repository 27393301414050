import type { EbizioEventConfig } from '../types';
import { EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const customerSignedInConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CUSTOMER_SIGNED_IN,
  handler: ({ detail }) => {
    logger('Customer Signed In');
    console.log(detail);
  },
};
